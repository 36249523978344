<template>
  <div class="footer-content">
    <div>Copyright © 山西智联华创科技有限公司 All Rights Reserved.</div>
    <a href="https://beian.miit.gov.cn/#/Integrated/index"
      >晋ICP备2024047604号</a
    >
  </div>
</template>

<script>
export default {
  name: "FooterContent",
};
</script>

<style scoped>
.footer-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 62px;
  color: #fff;
  font-size: 13px;
  background: #474747;
}
a {
  text-decoration: none;
  color: #f4f4f4;
}
</style>
