<template>
  <div class="about-content">
    <!-- <img src="../assets/product-banner.png" /> -->
    <img src="../assets/product-content.png" alt="" />
    <!-- <div class="container"> -->
    <!-- <div class="title-wrap">
        <div class="title">产品展示</div>
        <div class="text">您现在的位置：<span>首页</span>-产品展示</div>
      </div> -->
    <!-- <div class="content">
        <div class="grid" v-for="(item,index) in list" :key="index">
           <img class="banner1" :src="item.url">
           <div class="name">{{item.name}}</div>
        </div>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "ProductContent",
  data() {
    return {
      list: [
        {
          url: require("../assets/product/1.png"),
          name: "全自动横裁机",
        },
        {
          url: require("../assets/product/2.png"),
          name: "全自动浴巾横头缝边机",
        },
        {
          url: require("../assets/product/3.png"),
          name: "毛巾全自动纵缝机",
        },
        {
          url: require("../assets/product/4.png"),
          name: "毛巾全自动横头封边机",
        },
        {
          url: require("../assets/product/5.png"),
          name: "双面呢开缝机",
        },
        {
          url: require("../assets/product/6.png"),
          name: "双面呢开缝机",
        },
        {
          url: require("../assets/product/7.png"),
          name: "刀片修磨机",
        },
        {
          url: require("../assets/product/8.png"),
          name: "服装流水线电子看板 1000A",
        },
        {
          url: require("../assets/product/9.png"),
          name: "服装流水线电子看板 1000e",
        },
        {
          url: require("../assets/product/10.png"),
          name: "服装流水线电子看板 2000D",
        },
        {
          url: require("../assets/product/11.png"),
          name: "服装流水线电子看板 2000F",
        },
      ],
    };
  },
};
</script>

<style scoped>
.about-content {
  min-height: 100%;
}
img {
  width: 100%;
}
.container {
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
}
.banner1 {
  width: 100%;
  height: 302px;
}
.about-content > .container > .title-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 63px;
}
.about-content > .container > .title-wrap > .title {
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #d80f20;
  flex: 1;
}
.about-content > .container > .title-wrap > .text {
  font-size: 16px;
  color: #999999;
}
.about-content > .container > .title-wrap > .text > span {
  font-size: 16px;
  color: #666666;
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid > img {
  width: 276px;
  height: 202px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 0;
}
.name {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  color: #666666;
  margin-top: 12px;
  text-align: center;
}
</style>
